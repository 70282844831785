import { useCallback, useEffect, useState } from 'react';

import { getCollection } from '../Lib/canvas';
import PredictionRow from './PredictionRow'

const CollectionList = ({ token, collectionId, precision, addKafkaValue, addDownloadedValue,
  addStainedValue, addExportedValue, addThumbnailValue, addUploadedValue}) => {
  const [collection, setCollection] = useState();
  const collectionCallback = useCallback(async () => {
    const res = await getCollection(token, collectionId);
    setCollection(res);
  }, [token, collectionId]);

  useEffect(() => {
    collectionCallback();
  }, [collectionCallback]);

  return (
    collection &&
    collection.slides.map(predictionId => (
      <PredictionRow token={token} predictionId={predictionId} collectionId={collectionId} key={predictionId} precision={precision}
        addKafkaValue={addKafkaValue} addDownloadedValue={addDownloadedValue} addStainedValue={addStainedValue}
        addExportedValue={addExportedValue} addThumbnailValue={addThumbnailValue} addUploadedValue={addUploadedValue}
      />
    ))
  );
}

export default CollectionList;
