import { useCallback, useEffect, useState } from 'react';
import {
  Form, Grid, Button
} from 'semantic-ui-react'

import { logIn } from '../Lib/canvas';


const LoginForm = ({ setToken }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleLogin = async () => {
    const res = await logIn(email, password);
    setToken(res.tokens.access.token);
  }

  return (
    <div className="LoginForm">
      <Form size='big'>
        <Form.Input name='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          icon="user" fluid iconPosition='left' placeholder='E-mail address' />
        <Form.Input
          fluid
          icon='lock'
          iconPosition='left'
          placeholder='Password'
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          name='password'
          value={password}
        />
        <Button onClick={handleLogin} fluid size='big'>Sign in</Button>
      </Form>
    </div>
  );
}

export default LoginForm;
