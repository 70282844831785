import { useCallback, useEffect, useState } from 'react';
import { Popup, Table } from 'semantic-ui-react';

import { getPrediction, getSlide } from '../Lib/canvas';

const PredictionRow = ({ token, predictionId, collectionId, precision, addKafkaValue, addDownloadedValue,
  addStainedValue, addExportedValue, addThumbnailValue, addUploadedValue }) => {
  const [prediction, setPrediction] = useState();
  const [inImg, setInImg] = useState();
  const [kafkaValue, setKafkaValue] = useState();
  const [downloadedValue, setDownloadedValue] = useState();
  const [stainedValue, setStainedValue] = useState();
  const [exportedValue, setExportedValue] = useState();
  const [thumbnailValue, setThumbnailValue] = useState();
  const [uploadedValue, setUploadedValue] = useState();

  const setPredictionValues = useCallback(async () => {
    try {
      const res = await getPrediction(token, predictionId);
      if (!res.info) {
        res.info = {};
      }
      setPrediction(res);
      const res_inimg = await getSlide(token, res.in_img.replace(/\.[^.]*$/,''));
      setInImg(res_inimg);

      if (res.info.timestamp_job_consumed_ms && res.info.timestamp_job_produced_ms) {
        setKafkaValue((res.info.timestamp_job_consumed_ms - res.info.timestamp_job_produced_ms) / 60000);
      }

      if (res.info.timestamp_download_end_ms && res.info.timestamp_download_start_ms) {
        setDownloadedValue((res.info.timestamp_download_end_ms - res.info.timestamp_download_start_ms) / 60000);
      }

      if (res.info.timestamp_stain_end_ms && res.info.timestamp_stain_start_ms) {
        setStainedValue((res.info.timestamp_stain_end_ms - res.info.timestamp_stain_start_ms) / 60000);
      }

      if (res.info.timestamp_export_end_ms && res.info.timestamp_export_start_ms) {
        setExportedValue((res.info.timestamp_export_end_ms - res.info.timestamp_export_start_ms) / 60000);
      }

      if (res.info.timestamp_thumbnail_end_ms && res.info.timestamp_thumbnail_start_ms) {
        setThumbnailValue((res.info.timestamp_thumbnail_end_ms - res.info.timestamp_thumbnail_start_ms) / 60000);
      }

      if (res.info.timestamp_upload_end_ms && res.info.timestamp_upload_start_ms) {
        setUploadedValue((res.info.timestamp_upload_end_ms - res.info.timestamp_upload_start_ms) / 60000);
      }

    } catch (e) {
      console.log(e);
    }
  }, [token, predictionId]);

  useEffect(() => {
    setPredictionValues();
  }, [setPredictionValues]);

  useEffect(() => {
    if (kafkaValue) {
      addKafkaValue(predictionId, kafkaValue);
    }
  }, [kafkaValue, addKafkaValue, predictionId]);

  useEffect(() => {
    if (downloadedValue) {
      addDownloadedValue(predictionId, downloadedValue);
    }
  }, [downloadedValue, addDownloadedValue, predictionId]);

  useEffect(() => {
    if (stainedValue) {
      addStainedValue(predictionId, stainedValue);
    }
  }, [stainedValue, addStainedValue, predictionId]);

  useEffect(() => {
    if (exportedValue) {
      addExportedValue(predictionId, exportedValue);
    }
  }, [exportedValue, addExportedValue, predictionId]);

  useEffect(() => {
    if (thumbnailValue) {
      addThumbnailValue(predictionId, thumbnailValue);
    }
  }, [thumbnailValue, addThumbnailValue, predictionId]);

  useEffect(() => {
    if (uploadedValue) {
      addUploadedValue(predictionId, uploadedValue);
    }
  }, [uploadedValue, addUploadedValue, predictionId]);

  const createCell = value => {
    if (value) {
      return (
        <Popup content={value} trigger={
          <Table.Cell>{value.toFixed(precision)}</Table.Cell>
        } />
      );
    } else {
      return <Table.Cell>-</Table.Cell>;
    }
  }

  return (
    prediction && inImg &&
    <Table.Row>
      <Table.Cell>{prediction.name}</Table.Cell>
      <Table.Cell>{inImg.name}</Table.Cell>
      <Table.Cell>{inImg.size / 1e9}</Table.Cell>
      {createCell(kafkaValue)}
      {createCell(downloadedValue)}
      {createCell(stainedValue)}
      {createCell(exportedValue)}
      {createCell(thumbnailValue)}
      {createCell(uploadedValue)}
    </Table.Row>
  );
}

export default PredictionRow;
