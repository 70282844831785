import { useCallback, useEffect, useState } from 'react';
import {
  Form, Grid
} from 'semantic-ui-react'



const SettingsController = ({ precision, setPrecision }) => {

  return (
    <Form>
      <Form.Input
        label={`Precision: ${precision} `}
        min={0}
        max={8}
        name='Precision'
        onChange={(e, { value }) => setPrecision(value)}
        step={1}
        type='range'
        value={precision}
      />
    </Form>
  );
}

export default SettingsController;
