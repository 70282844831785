import { useCallback, useEffect, useState } from 'react';
import { Popup, Table } from 'semantic-ui-react';

import CollectionList from './CollectionList';

const StudyTable = ({ token, study, precision }) => {
  const [collectionIds, setCollectionIds] = useState([]);

  const [kafkaValues, setKafkaValues] = useState({});
  const [kafkaTotal, setKafkaTotal] = useState(0);
  const [slideSizeTotal, setSlideSizeTotal] = useState(0);
  const [kafkaAverage, setKafkaAverage] = useState(0);

  const [downloadedValues, setDownloadedValues] = useState({});
  const [downloadedTotal, setDownloadedTotal] = useState(0);
  const [downloadedAverage, setDownloadedAverage] = useState(0);

  const [stainedValues, setStainedValues] = useState({});
  const [stainedTotal, setStainedTotal] = useState(0);
  const [stainedAverage, setStainedAverage] = useState(0);

  const [exportedValues, setExportedValues] = useState({});
  const [exportedTotal, setExportedTotal] = useState(0);
  const [exportedAverage, setExportedAverage] = useState(0);

  const [thumbnailValues, setThumbnailValues] = useState({});
  const [thumbnailTotal, setThumbnailTotal] = useState(0);
  const [thumbnailAverage, setThumbnailAverage] = useState(0);

  const [uploadedValues, setUploadedValues] = useState({});
  const [uploadedTotal, setUploadedTotal] = useState(0);
  const [uploadedAverage, setUploadedAverage] = useState(0);

  useEffect(() => {
    async function getData() {
      setCollectionIds(study.collections);
    }
    getData();
  }, [study]);

  const addKafkaValue = useCallback((predictionId, value) => {
    setKafkaValues(prevValues => ({ ...prevValues, [predictionId]: value }));
  }, []);

  const addDownloadedValue = useCallback((predictionId, value) => {
    setDownloadedValues(prevValues => ({ ...prevValues, [predictionId]: value }));
  }, []);

  const addStainedValue = useCallback((predictionId, value) => {
    setStainedValues(prevValues => ({ ...prevValues, [predictionId]: value }));
  }, []);

  const addExportedValue = useCallback((predictionId, value) => {
    setExportedValues(prevValues => ({ ...prevValues, [predictionId]: value }));
  }, []);

  const addThumbnailValue = useCallback((predictionId, value) => {
    setThumbnailValues(prevValues => ({ ...prevValues, [predictionId]: value }));
  }, []);

  const addUploadedValue = useCallback((predictionId, value) => {
    setUploadedValues(prevValues => ({ ...prevValues, [predictionId]: value }));
  }, []);

  useEffect(() => {
    let total = 0;
    Object.values(kafkaValues).forEach(value => {
      total += value;
    });
    setKafkaTotal(total);

    const len = Object.keys(kafkaValues).length;
    if (len > 0) {
      setKafkaAverage(total / len);
    } else {
      setKafkaAverage(0);
    }
  }, [kafkaValues]);

  useEffect(() => {
    let total = 0;
    Object.values(downloadedValues).forEach(value => {
      total += value;
    });
    setDownloadedTotal(total);

    const len = Object.keys(downloadedValues).length;
    if (len > 0) {
      setDownloadedAverage(total / len);
    } else {
      setDownloadedAverage(0);
    }
  }, [downloadedValues]);

  useEffect(() => {
    let total = 0;
    Object.values(stainedValues).forEach(value => {
      total += value;
    });
    setStainedTotal(total);

    const len = Object.keys(stainedValues).length;
    if (len > 0) {
      setStainedAverage(total / len);
    } else {
      setStainedAverage(0);
    }
  }, [stainedValues]);

  useEffect(() => {
    let total = 0;
    Object.values(exportedValues).forEach(value => {
      total += value;
    });
    setExportedTotal(total);

    const len = Object.keys(exportedValues).length;
    if (len > 0) {
      setExportedAverage(total / len);
    } else {
      setExportedAverage(0);
    }
  }, [exportedValues]);

  useEffect(() => {
    let total = 0;
    Object.values(thumbnailValues).forEach(value => {
      total += value;
    });
    setThumbnailTotal(total);

    const len = Object.keys(thumbnailValues).length;
    if (len > 0) {
      setThumbnailAverage(total / len);
    } else {
      setThumbnailAverage(0);
    }
  }, [thumbnailValues]);

  useEffect(() => {
    let total = 0;
    Object.values(uploadedValues).forEach(value => {
      total += value;
    });
    setUploadedTotal(total);

    const len = Object.keys(uploadedValues).length;
    if (len > 0) {
      setUploadedAverage(total / len);
    } else {
      setUploadedAverage(0);
    }
  }, [uploadedValues]);

  useEffect(() => {
    setKafkaValues({});
    setDownloadedValues({});
    setStainedValues({});
    setExportedValues({});
    setThumbnailValues({});
    setUploadedValues({});
  }, [collectionIds]);


  return (
    <div>
      {collectionIds &&
        <Table celled>
          <Table.Header className="Header">
            <Table.Row>
              <Table.HeaderCell>Prediction</Table.HeaderCell>
              <Table.HeaderCell>Slide Name</Table.HeaderCell>
              <Table.HeaderCell>Slide Size (GB)</Table.HeaderCell>
              <Table.HeaderCell>Kafka (minutes)</Table.HeaderCell>
              <Table.HeaderCell>Downloaded (minutes)</Table.HeaderCell>
              <Table.HeaderCell>Stained (minutes)</Table.HeaderCell>
              <Table.HeaderCell>Exported (minutes)</Table.HeaderCell>
              <Table.HeaderCell>Create Thumbnail (minutes)</Table.HeaderCell>
              <Table.HeaderCell>Uploaded (minutes)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {collectionIds.map(collectionId => (
              <CollectionList token={token} collectionId={collectionId} precision={precision} key={collectionId}
                addKafkaValue={addKafkaValue} addDownloadedValue={addDownloadedValue} addStainedValue={addStainedValue}
                addExportedValue={addExportedValue} addThumbnailValue={addThumbnailValue} addUploadedValue={addUploadedValue}
              />
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell><b>Total</b></Table.HeaderCell>
              <Table.HeaderCell><b>----</b></Table.HeaderCell>
              <Table.HeaderCell><b>----</b></Table.HeaderCell>
              {/* <Popup content={slideSizeTotal} trigger={
                <Table.HeaderCell>{slideSizeTotal.toFixed(precision)}</Table.HeaderCell>
              } /> */}
              <Popup content={kafkaTotal} trigger={
                <Table.HeaderCell>{kafkaTotal.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={downloadedTotal} trigger={
                <Table.HeaderCell>{downloadedTotal.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={stainedTotal} trigger={
                <Table.HeaderCell>{stainedTotal.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={exportedTotal} trigger={
                <Table.HeaderCell>{exportedTotal.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={thumbnailTotal} trigger={
                <Table.HeaderCell>{thumbnailTotal.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={uploadedTotal} trigger={
                <Table.HeaderCell>{uploadedTotal.toFixed(precision)}</Table.HeaderCell>
              } />
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell><b>Average</b></Table.HeaderCell>
              <Table.HeaderCell><b>----</b></Table.HeaderCell>
              <Table.HeaderCell><b>----</b></Table.HeaderCell>
              <Popup content={kafkaAverage} trigger={
                <Table.HeaderCell>{kafkaAverage.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={downloadedAverage} trigger={
                <Table.HeaderCell>{downloadedAverage.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={stainedAverage} trigger={
                <Table.HeaderCell>{stainedAverage.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={exportedAverage} trigger={
                <Table.HeaderCell>{exportedAverage.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={thumbnailAverage} trigger={
                <Table.HeaderCell>{thumbnailAverage.toFixed(precision)}</Table.HeaderCell>
              } />
              <Popup content={uploadedAverage} trigger={
                <Table.HeaderCell>{uploadedAverage.toFixed(precision)}</Table.HeaderCell>
              } />
            </Table.Row>
          </Table.Footer>
        </Table>
      }
    </div>
  )
}

export default StudyTable;