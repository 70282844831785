import { useCallback, useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'

import './App.css';
import StudySelector from './Components/StudySelector';
import StudyTable from './Components/StudyTable';
import SettingsController from './Components/SettingsController';
import LoginForm from './Components/LoginForm';

function App() {
  const [token, setToken] = useState();
  const [currentStudy, setCurrentStudy] = useState();
  const [precision, setPrecision] = useState(3);

  return (
    <div className="App">
      {!token && <LoginForm setToken={setToken} />}
      {token && <Menu className="Menu">
        <Menu.Item>
          {token && <StudySelector className="StudySelector" token={token} setCurrentStudy={setCurrentStudy} />}
        </Menu.Item>
        <Menu.Item>
          {precision && currentStudy && <SettingsController precision={precision} setPrecision={setPrecision} />}
        </Menu.Item>
      </Menu>
      }
      <div className="StudyTable">
        {currentStudy && precision && <StudyTable token={token} study={currentStudy} precision={precision} setPrecision={setPrecision} />}
      </div>
    </div>
  );
}

export default App;
