import { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { getStudies } from '../Lib/canvas';

const StudySelector = ({ token, setCurrentStudy }) => {
  const [studies, setStudies] = useState();
  const [studyOptions, setStudyOptions] = useState();

  useEffect(() => {
    async function getData() {
      const res = await getStudies(token);
      setStudies(res);
      setStudyOptions(formatDrop(res))
    }
    getData();
  }, [token]);

  const formatDrop = (s) => {
    return s.map(study => { return { key: study.id, value: study.id, text: study.name } });
  }

  const getStudyFromId = (id) => {
    for (let study of studies) {
      if (study.id === id) {
        return study;
      }
    }
  }

  return (
    <div>
      {studyOptions && <Dropdown
        placeholder='Select Study'
        flex='true'
        onChange={(e, { value }) => setCurrentStudy(getStudyFromId(value))}
        selection
        options={studyOptions} />}
    </div>
  );
}

export default StudySelector;