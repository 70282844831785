const config = require('../config');

const logIn = async (email, password) => {
  const server_url = `${config.API_URL}/v1/auth/login`

  const response = await fetch(server_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password
    })
  });

  if (response.status !== 200) {
    throw new Error('Login failed');
  }
  return response.json();
}

const getStudies = async (token) => {
  const server_url = `${config.API_URL}/v1/studies`

  const response = await fetch(server_url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (response.status !== 200) {
    throw new Error('Failed to get studies');
  }
  return response.json();
}

const getStudyById = async (token, id) => {
  const server_url = `${config.API_URL}/v1/studies/${id}`

  const response = await fetch(server_url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (response.status !== 200) {
    throw new Error('Failed to get study');
  }
  return response.json();
}

const getCollection = async (token, collectionId) => {
  const server_url = `${config.API_URL}/v1/collections/${collectionId}`

  const response = await fetch(server_url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (response.status !== 200) {
    throw new Error('Failed to get collection');
  }
  return response.json();
}

const getPrediction = async (token, predictionId) => {
  const server_url = `${config.API_URL}/v1/predictions/${predictionId}`

  const response = await fetch(server_url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (response.status !== 200) {
    throw new Error('Failed to get prediction');
  }
  return response.json();
}

const getSlide = async (token, slideId) => {
  const server_url = `${config.API_URL}/v1/slides/${slideId}`

  const response = await fetch(server_url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (response.status !== 200) {
    throw new Error('Failed to get prediction');
  }
  return response.json();
}

export {
  logIn,
  getStudies,
  getStudyById,
  getCollection,
  getPrediction,
  getSlide
}
